<template>
    <div class="p-grid panel-demo">
        <div class="p-col-12 p-md-12">
            <div class="card p-fluid">
                <h5>Welcome to ArenaCX!</h5>
            </div>
        </div>

        <div class="p-col-12 p-md-12">
            <div class="card p-fluid">
                <div><img id="logo" src="assets/layout/images/arenacx_teal_logo.png" alt="logo" style="height: 12.25rem" /></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['currentUser'],

    data() {
        return {};
    },

    memberHomeService: null,

    created() {},

    async mounted() {},

    methods: {},
};
</script>

<style scoped lang="scss">
.para {
    line-height: 1.5;
    margin: 0;
}
</style>
